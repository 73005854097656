import { registerApplication, start } from "single-spa";
import {
	constructApplications,
	constructLayoutEngine,
} from "single-spa-layout";
import "./base.scss";
import "@pons/auth";
import {
	ResolvedRouteChild,
	ResolvedRoutesConfig,
} from "single-spa-layout/dist/types/isomorphic/constructRoutes";

function withRoutes(
	node: Node,
	routes: ResolvedRouteChild[]
): ResolvedRouteChild {
	node["routes"] = routes;
	return node;
}

function application(name: string): ResolvedRouteChild {
	return {
		type: "application",
		name: name,
	};
}

function elementWithClasses(
	name: string,
	classes: string[] = [],
	...routes: ResolvedRouteChild[]
): ResolvedRouteChild {
	const element = document.createElement(name);
	element.classList.add(...classes);
	return withRoutes(element, routes);
}

function element(
	name: string,
	...routes: ResolvedRouteChild[]
): ResolvedRouteChild {
	const element = document.createElement(name);
	return withRoutes(element, routes);
}

type TestFun = (subject: string) => boolean;

function route(
	regex: RegExp | TestFun,
	...routes: ResolvedRouteChild[]
): ResolvedRouteChild {
	let testFun: TestFun;
	if (regex instanceof RegExp) testFun = (subject) => regex.test(subject);
	else testFun = regex;

	return {
		type: "route",
		activeWhen(location: Location): boolean {
			const subject = `${location.pathname}${location.search}${location.hash}`;
			return testFun(subject);
		},
		routes,
		path: "",
	};
}

function anyMatch(subject: string, ...regexes: RegExp[]): boolean {
	for (const regex of regexes) if (regex.test(subject)) return true;
	return false;
}

function notAnyMatch(subject: string, ...regexes: RegExp[]): boolean {
	return !anyMatch(subject, ...regexes);
}

const regexes = {
	aboFoundry: /^\/abo-foundry(\/?|\/.*)$/,
	subscriptionShopShop: /^(\/subscription(\/?|\/.*))$/,
	subscriptionShopManagement: /^(\/account\/contracts(\/?|\/.*))$/,
	accountManager: /^\/account(\/?|\/.*)/,
	licenseManager: /^(\/account\/license-manager(\/?|\/.*))$/,
	vocabularyTrainer: /^\/vocabulary-trainer(\/?|\/.*)$/,
	login: /^\/account\/login(\/?|\/.*|\?.*)$/,
	register: /^\/account\/register(\/?|\/.*|\?.*)$/,
	forgotPassword: /^\/account\/forgotPassword(\/?|\/.*|\?.*)$/,
};

const shouldNotShowAccountNav = [
	regexes.login,
	regexes.register,
	regexes.forgotPassword,
];

const routes: ResolvedRoutesConfig = {
	mode: "history",
	base: "/",
	containerEl: "body",
	redirects: {},
	routes: [
		route(
			(s) => notAnyMatch(s, regexes.accountManager),
			element("nav", {
				type: "application",
				name: "@pons/langenscheidt-nav",
			}),
			element(
				"main",

				route(
					regexes.aboFoundry,
					application("@pons/langenscheidt-abo-foundry")
				),

				route(
					regexes.subscriptionShopShop,
					application("@pons/subscription-shop-frontend")
				),

				route(
					regexes.vocabularyTrainer,
					application("@pons/langenscheidt-vt-web")
				),

				route(
					(subject) =>
						notAnyMatch(
							subject,
							...Object.keys(regexes).map((k) => regexes[k])
						),
					application("@pons/langenscheidt-cms")
				)
			),

			element("aside", application("@pons/langenscheidt-social")),

			element("footer", application("@pons/langenscheidt-footer"))
		),

		route(
			(s) =>
				notAnyMatch(s, ...shouldNotShowAccountNav) &&
				regexes.accountManager.test(s),
			element("nav", application("@pons/langenscheidt-account-header")),
			elementWithClasses(
				"div",
				["account-columns"],

				element("nav", application("@pons/langenscheidt-account-nav")),

				element(
					"div",
					route(
						regexes.subscriptionShopManagement,
						application("@pons/subscription-shop-frontend")
					),

					route((subject: string): boolean => {
						return (
							notAnyMatch(
								subject,
								regexes.subscriptionShopManagement,
								regexes.licenseManager
							) && regexes.accountManager.test(subject)
						);
					}, application("@pons/account-manager-web")),

					route(
						regexes.licenseManager,
						application("@pons/license-web")
					)
				)
			)
		),

		route(
			(s) => anyMatch(s, ...shouldNotShowAccountNav),
			element("nav", application("@pons/langenscheidt-account-header")),
			elementWithClasses(
				"div",
				["account-columns"],
				element("div", application("@pons/account-manager-web"))
			)
		),
	],
};

const applications = constructApplications({
	routes,
	loadApp({ name }) {
		return System.import(name);
	},
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();

start();
